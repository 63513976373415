import React from 'react';
interface ProgressBarProps {
    skillName: string;
    skillValue: number;
}

const getColorFromValue = (value: number): string => {
    const clampedValue = Math.min(Math.max(value, 0), 100);

    let red, green, blue;

    if (clampedValue <= 50) {
        red = 128;
        green = 128;
        blue = 128;
    } else {
        red = Math.round(255 - (255 * ((clampedValue - 50) / 50)));
        green = Math.round(165 - (37 * ((clampedValue - 50) / 50)));
        blue = Math.round(255 - (37 * ((clampedValue - 50) / 50)));
    }

    // Return the color in RGB format
    return `rgb(${red}, ${green}, ${blue})`;
};


const ProgressBar: React.FC<ProgressBarProps> = ({ skillName, skillValue }) => {
    const value = Math.min(Math.max(skillValue, 0), 100);
    const skillColor = getColorFromValue(skillValue);
    return (
        <>
            <h4 className='heading4 progressbar__label interact-off'>
                <span className='progressbar__label__text'>{skillName}</span>
                <span className='progressbar__label__value text-end'>{value}%</span>
            </h4>
            <div
                className='progressbar'
                role="progressbar"
                aria-label={`Progress of ${skillName}`}
                aria-valuenow={value}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{
                    borderColor: skillColor
                }}
                data-aos='zoom-out-up'
                data-aos-anchor-placement="bottom-bottom"
            >
                <div className='progressbar__value'
                    style={{
                        width: `${value}%`,
                        backgroundColor: skillColor
                    }}
                />
            </div>
        </>
    );
};

export default ProgressBar;
