import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <p>© 2024 Robert Wood. All rights reserved.</p>
            <a href='/privacy-policy.html'>Privacy policy</a>
        </footer>
    );
};

export default Footer;
