import React from 'react';
import ProgressBar from '../ProgressBar';

const Skills: React.FC = () => {
    return (
        <section id="skills" className="skills section light-background">
            <div className="container section-title" data-aos="fade-up">
                <h2>Skills</h2>
                <p>I have developed a strong foundation of knowledge in many areas, but I believe there is always more to learn. As such, I prefer not to consider myself an expert, as I value continuous growth and exploration.</p>
                <p>Every day is a learning day!</p>
            </div>
            <div className="container">
                <div className="row skills-content">
                    <div className="col-lg-4">
                        <h3 className='heading3'>Programming</h3>
                        <ProgressBar skillName='HTML' skillValue={99.5} />
                        <ProgressBar skillName='JavaScript' skillValue={95} />
                        <ProgressBar skillName='React.js' skillValue={75} />
                        <ProgressBar skillName='Next.js' skillValue={75} />
                        <ProgressBar skillName='Typescript' skillValue={75} />
                        <ProgressBar skillName='Magento 2 (Front-end)' skillValue={90} />
                    </div>
                    <div className="col-lg-4">
                        <h3 className='heading3'>Design</h3>
                        <ProgressBar skillName='Photoshop' skillValue={90} />
                        <ProgressBar skillName='Illustrator' skillValue={85} />
                        <ProgressBar skillName='InDesign' skillValue={80} />
                        <ProgressBar skillName='Figma' skillValue={65} />
                        <ProgressBar skillName='CorelDraw' skillValue={90} />
                        <ProgressBar skillName='Core PhotoPaint' skillValue={90} />
                        {/* <ProgressBar skillName='Affinity Photo 2' skillValue={50} /> */}
                    </div>
                    <div className="col-lg-4">
                        <h3 className='heading3'><abbr title='Cascading Style Sheets'>CSS</abbr>, Preprocessors & Frameworks</h3>
                        <ProgressBar skillName='CSS' skillValue={99.5} />
                        <ProgressBar skillName='Sass/SCSS' skillValue={90} />
                        <ProgressBar skillName='Less' skillValue={60} />
                        <ProgressBar skillName='Tailwind CSS' skillValue={70} />
                        <ProgressBar skillName='Bootstrap' skillValue={70} />
                        <ProgressBar skillName='PostCSS' skillValue={90} />
                    </div>
                    <div className="col-lg-4">
                        <h3 className='heading3'>Package Management</h3>
                        <ProgressBar skillName='NPM' skillValue={90} />
                        <ProgressBar skillName='Webpack' skillValue={80} />
                        <ProgressBar skillName='Gulp' skillValue={80} />
                        <ProgressBar skillName='Node.js' skillValue={60} />
                    </div>
                    <div className="col-lg-4">
                        <h3 className='heading3'>Version Control</h3>
                        <ProgressBar skillName='Git' skillValue={80} />
                        <ProgressBar skillName='Subversion' skillValue={50} />
                        <ProgressBar skillName='Team Foundation Server' skillValue={50} />
                    </div>
                    <div className="col-lg-4">
                        <h3 className='heading3'><abbr title='Search Engine Marketing'>SEM</abbr></h3>
                        <ProgressBar skillName='CRO' skillValue={85} />
                        <ProgressBar skillName='Technical SEO' skillValue={95} />

                    </div>
                    <div className="col-lg-4">
                        <h3 className='heading3'>Project Management</h3>
                        <ProgressBar skillName='Jira' skillValue={85} />
                        <ProgressBar skillName='Confluence' skillValue={65} />
                        <ProgressBar skillName='Linear' skillValue={80} />
                        <ProgressBar skillName='Notion' skillValue={70} />
                    </div>
                    <div className="col-lg-4">
                        <h3 className='heading3'>Integrated & Cloud Services</h3>
                        <ProgressBar skillName='Cloudflare' skillValue={90} />
                        <ProgressBar skillName='AWS S3' skillValue={95} />
                        <ProgressBar skillName='TinyPNG' skillValue={95} />


                    </div>
                </div>
            </div>
        </section >
    );
};

export default Skills;