import React from 'react';
import { PiCaretRight } from "react-icons/pi";
import profileImage from '../../assets/images/profile_large.jpg';

const About: React.FC = () => {
    return (
        <>
            <section id="about" className="about section">

                <div className="container section-title" data-aos="fade-up">
                    <h2>About</h2>
                    <p>I am an experienced Senior Front-End Developer, Team Lead, and Product Owner with a proven track record in optimising e-commerce platforms, reducing website load times by 80%, and leading teams with a 100% retention rate.</p>
                </div>
                <div className="container">

                    <div className="row gy-4 justify-content-center" data-aos="fade-up">
                        <div className="about__profile-left col-lg-4">
                            <img src={profileImage} alt='' className='img-fluid about__profile-picture' loading="lazy" />
                        </div>
                        <div className="col-lg-8 content">
                            <h3>Multi-skilled technologist</h3>
                            <p className="fst-italic">
                                With over 30 years of diverse experience in technology and design, I blend a rich array of soft and hard skills to empower employers and clients in achieving exceptional results.
                            </p>
                            <hr />
                            <h3>My journey</h3>
                            <p>
                                I began my career as an administrative assistant, where I quickly discovered a knack for supporting colleagues through design, layout, and troubleshooting, particularly within Microsoft Office and WordPerfect applications. My proactive, solutions-focused approach soon led to a part-time role in Technical Support. This opportunity allowed me to deepen my technical expertise while building both hard and soft skills through collaboration with internal teams and external clients alike.
                            </p>
                            <p>
                                In this role, I noticed a knowledge gap in our department and decided to dive into web development by picking up Microsoft FrontPage. What started as a self-guided exploration soon laid the groundwork for my career. The simple web pages I created as part of my learning became the company's very first website, marking a turning point in my professional journey.
                            </p>
                            <p>
                                From there, I was promoted to Webmaster, evolving into the company's 'go-to guy' for all things Internet. My portfolio expanded to include internet and intranet sites for prominent organizations such as Lloyd's of London, the Association of Chief Police Officers, and the NHS. My work even caught Microsoft's attention, earning me an invitation to demonstrate at a conference in Brighton on the Dynamic Web's rapid evolution.
                            </p>
                            <p>
                                Throughout my career, I have enjoyed mentoring and leading teams, guiding Technical Support juniors to achieve industry-recognized qualifications from Microsoft, Novell, and Lotus. More recently, I built and managed a front-end development team within a fast-paced, agile environment. I'm particularly proud of my 100% retention rate across all teams I've managed, reflecting my commitment to fostering a positive and growth-oriented workplace.
                            </p>
                            <hr />
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul>
                                        <li><PiCaretRight /><strong>Email:</strong> <span><a href="mailto:hello@robertwood.me">hello@robertwood.me</a> &ndash; or use the <a href='#contact'>contact form</a> below.</span></li>
                                        <li><PiCaretRight /><strong>Availability:</strong> <span style={{ color: '#a0a0a0' }}>Not currently available for freelance contract work.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section >
        </>
    );
};

export default About;