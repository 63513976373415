import React from 'react';

const Website: React.FC = () => {

    return (
        <>
            <section className="website" id="website">
                <div className="container section-title" data-aos="fade-up">
                    <h2>This website</h2>
                    <p>I build this site using ReactJS and Typescript.</p>
                    <p>There is a small amount of vanilla JavaScript.</p>
                    <p>The CSS is a mixture of <span title="🤮">Bootstrap</span> and Sass.</p>
                    <p>Animation is provided by <a href="https://michalsnik.github.io/aos/" target='_blank'>AOS library</a>.</p>
                    <p>Hosting is provided by NameCheap.</p>
                    <br />
                    <br />
                    <br />
                    <br />
                    <p>I am <span className='uppercase'>definitely</span> my own worst customer!</p>
                </div>
            </section >
        </>
    );
};

export default Website;