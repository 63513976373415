import React, { } from 'react';
import Hamburger from './Hamburger';
import MenuLink from './MenuLink';
import Social from './content/Social';

import profileImage from '../assets/images/image.jpg';

interface SideMenuProps {
    isMenuOpen: boolean;
    toggleMenu: () => void;
}

const Menu: React.FC<SideMenuProps> = ({ isMenuOpen, toggleMenu }) => {
    return (
        <header className='header'>
            <div id="skipToContent" className="skip-to-content">
                <a className="skip-link" href="#content">Skip to content</a>
                <a className="skip-link" href="#footer">Skip to footer</a>
            </div>
            <Hamburger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            <nav className={`side-menu ${isMenuOpen ? 'open' : ''}`}>
                <div className='menu-header'>
                    <div className="profile">
                        <img src={profileImage} width={159} height={159} alt='' />
                        <h1 className="sitename">Robert Wood</h1>
                        <Social />
                    </div>
                </div>
                <div id="menuLinks" className='menu-links'>
                    {/* <MenuLink menuItemText='Home' menuItemLink='#intro' menuItemClass='' /> */}
                    <MenuLink menuItemText='About' menuItemLink='#about' toggleMenu={toggleMenu} />
                    {/* <MenuLink menuItemText='CV' menuItemLink='#resume' menuItemClass='' /> */}
                    <MenuLink menuItemText='Skills' menuItemLink='#skills' toggleMenu={toggleMenu} />
                    {/* <MenuLink menuItemText='Portfolio' menuItemLink='#portfolio' menuItemClass='' /> */}
                    {/* <MenuLink menuItemText='Services' menuItemLink='#services' menuItemClass='' /> */}
                    {/* <MenuLink menuItemText='Testimonials' menuItemLink='#testimonials' menuItemClass='' /> */}
                    <MenuLink menuItemText='Contact' menuItemLink='#contact' toggleMenu={toggleMenu} />
                    <MenuLink menuItemText='This build' menuItemLink='#website' toggleMenu={toggleMenu} />
                </div>
            </nav>
            {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}
        </header>
    );
};

export default Menu;


