import React from 'react';
import Typed from 'typed.js';
import heroImage from '../../assets/images/hero.jpg';

const Intro: React.FC = () => {
    const el = React.useRef(null);

    React.useEffect(() => {
        const typed = new Typed(el.current, {
            strings: [
                'developer{ tech:&apos;HTML&apos; }^500',
                'developer{ tech:&apos;PHP&apos; }^500',
                'developer{ tech:&apos;JavaScript&apos; }^500',
                'developer{ tech:&apos;React.js&apos; }^500',
                'developer{ tech:&apos;next.js&apos; }^500',
                'developer{ tech:&apos;Typescript&apos; }^500',
                'developer{ tech:&apos;CSS&apos; }^500',
                'developer{ tech:&apos;Sass&apos; }^500',
                'developer{ tech:&apos;SQL&apos; }^500',
                'developer{ tech:&apos;SEO&apos; }^500',
                'developer{ tech:&apos;CRO&apos; }^500',
                'designer{ media:&apos;UI&apos; }^500',
                'designer{ media:&apos;UX&apos; }^500',
                'designer{ media:&apos;Web&apos; }^500',
                'designer{ media:&apos;Print&apos; }^500',
                'designer{ media:&apos;Apparel&apos; }^500',
                'customer-service{ &apos;nth-line-support&apos; }^500',
                'next-team-member?',
            ],
            typeSpeed: 65,
            loop: false,
            smartBackspace: true,
            cursorChar: '█',
        });

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <section id="intro" className="hero section dark">
            <img src={heroImage} alt="" className="heroimage interact-off" />
            <div className="container">
                <h2>Robert Wood</h2>
                <h3 className='interact-off'>Perpetually under construction...</h3>
                <p className='typer'>.your::<a href="#contact" title="Contact me" ><span ref={el} className='interact-off' /></a></p>
            </div>
        </section>
    );
};

export default Intro; 