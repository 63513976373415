import React from 'react';
import { PiLinkedinLogo, PiGithubLogo, PiButterfly, PiInstagramLogo } from "react-icons/pi";

const Social: React.FC = () => {
    return (
        <div className='socialLinks'>
            <i><a href="https://www.linkedin.com/in/robwood/" title='LinkedIn' target='linkedin' ><PiLinkedinLogo /></a></i>
            <i><a href="https://github.com/cubik73" title='Github' target='github'><PiGithubLogo /></a></i>
            <i><a href="https://bsky.app/profile/cubik.bsky.social" title='Bluesky' target='bluesky'><PiButterfly /></a></i>
            <i><a href="https://www.instagram.com/cu3ik/" title='Instagram (Personal)' target='instagram'><PiInstagramLogo /></a></i>
        </div>
    );
};

export default Social;