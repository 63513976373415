import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import Menu from './components/Menu';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

import Intro from './components/content/Intro';
import About from './components/content/About';
import Skills from './components/content/Skills';
/* import Resume from './components/content/Resume'; */
/* import Portfolio from './components/content/Portfolio'; */
/* import Services from './components/content/Services' */
/* import Testimonials from './components/content/Tesimonials'; */
import Contact from './components/content/Contact';
import Website from './components/content/Website';

const App: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div className="App">
            <Menu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />

            <main className="content" id="content">
                <Intro />
                <About />
                <Skills />
                {/* <Resume /> */}
                {/* <Portfolio /> */}
                {/* <Services /> */}
                {/* <Testimonials /> */}
                <Contact />
                <Website />
            </main>
            {/* </div> */}
            <Footer />
            <ScrollToTop />

        </div>
    );
};

export default App;