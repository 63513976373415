import React from 'react';
import ScrollIntoView from 'react-scroll-into-view'

interface MenuItemProps {
    menuItemText: string;
    menuItemLink: string;
    menuItemClass?: string;
    toggleMenu: () => void;
}

const MenuLink: React.FC<MenuItemProps> = ({ menuItemText, menuItemLink, menuItemClass, toggleMenu }) => {
    return (
        <ScrollIntoView selector={menuItemLink} onClick={toggleMenu}>
            <button className={`menu-links_item ` + menuItemClass}>
                {menuItemText}
            </button>
        </ScrollIntoView>
    );
};

export default MenuLink;