import React, { useState, FormEvent } from 'react';

const Contact: React.FC = () => {

    const [isNamePopulated, setIsNamePopulated] = useState(false);
    const [isEmailPopulated, setIsEmailPopulated] = useState(false);
    const [isSubjectPopulated, setIsSubjectPopulated] = useState(false);
    const [isMessagePopulated, setIsMessagePopulated] = useState(false);
    const [formStatus, setFormStatus] = useState<string | null>(null);

    const toggleLabel = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, setPopulated: React.Dispatch<React.SetStateAction<boolean>>) => {
        const target = event.currentTarget as HTMLInputElement | HTMLTextAreaElement;
        setPopulated(target.value.trim().length > 0);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        const data = new FormData(form);

        try {
            const response = await fetch(form.action, {
                method: form.method,
                body: data,
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (response.ok) {
                setFormStatus("Thank you for your message. I'll be in touch soon.");
                form.reset();
                setIsNamePopulated(false);
                setIsMessagePopulated(false);
            } else {
                const errorData = await response.json();
                if ('errors' in errorData) {
                    setFormStatus(errorData.errors.map((error: { message: string }) => error.message).join(", "));
                } else {
                    setFormStatus("Sorry, there was a problem sending your message.");
                }
            }
        } catch (error) {
            setFormStatus("Sorry, there was a problem sending your message.");
        }
    };














    return (
        <section id="contact" className="contact section">
            <div className="container section-title" data-aos="fade-up">
                <h2>Contact</h2>
                <p>Please use the form to contact me, and I will get back to you.</p>
            </div>
            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <div className="row gy-4">
                    <div className="col-lg-12">
                        <form action="https://formspree.io/f/mwpkblod" method="post" className="contact-form" id="contactForm" data-aos="fade-up" data-aos-delay="200" onSubmit={handleSubmit}>
                            <fieldset className='fieldset contact'>
                                <div className="row gy-4">
                                    <div className={`col-md-6 field email required ${isNamePopulated ? 'populated' : ''}`}>
                                        <label className="label pb-2" htmlFor="name-field"><span>Your name</span></label>
                                        <div className="control">
                                            <input id="name-field" className="form-control" type="text" title="Name" name="name" autoComplete="name" required onInput={(event) => toggleLabel(event, setIsNamePopulated)} />
                                        </div>
                                    </div>

                                    <div className={`col-md-6 field email required ${isEmailPopulated ? 'populated' : ''}`}>
                                        <label className="label" htmlFor="email-field"><span>Email address</span></label>
                                        <div className="control">
                                            <input id="email-field" className="form-control input-text lowercase" type="email" title="Email" name="email" autoComplete="email" data-validate="{required:true, 'validate-email':true}" required onInput={(event) => toggleLabel(event, setIsEmailPopulated)} />
                                        </div>
                                    </div>

                                    <div className={`w-100 field subject required ${isSubjectPopulated ? 'populated' : ''}`}>
                                        <label className="label pb-2" htmlFor="subject-field"><span>Subject</span></label>
                                        <div className="control">
                                            <input id="subject-field" className="form-control" type="text" title="Subject" name="subject&#8205;" autoComplete='off' onInput={(event) => toggleLabel(event, setIsSubjectPopulated)} required />
                                        </div>
                                    </div>

                                    <div className={`w-100 field message required ${isMessagePopulated ? 'populated' : ''}`}>
                                        <label className="label pb-2" htmlFor="message-field"><span>Message</span></label>
                                        <div className="control">
                                            <textarea className="contact-form__message w-100" name="message" data-rows="10" id="message-field" required onInput={(event) => toggleLabel(event, setIsMessagePopulated)} ></textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        {/* Status Message */}

                                        <div className="form-actions">
                                            <div>
                                                <p><span><span className='negative'>*</span> Field is mandatory</span></p>
                                            </div>
                                            <div className='text-end'>
                                                <button type="submit">Send</button>
                                                <div id="contactFormStatus" aria-live="polite">
                                                    {formStatus && <p>{formStatus}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>


                </div>

            </div>

        </section>
    );
};

export default Contact;

